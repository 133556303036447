import Splitting from "splitting";


import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Observer } from "gsap/Observer";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { TextPlugin } from "gsap/TextPlugin";

/* The following plugins are Club GSAP perks */
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import { SplitText } from "gsap/SplitText";


window.onload = function()
{
    setup();
};

function setup()
{
    //const splitResult = Splitting( { target: document.querySelector('.telex'), by: 'chars' } );
    const header = document.getElementById( 'header' );

    /*
    setTimeout( () => {
        document.querySelectorAll( '.telex' ).forEach( (elem) => {
            elem.classList.add( 'active' );
        });
    },1000 );
     */

    /*
    document.querySelector( '#test' ).querySelectorAll( '.char' ).forEach( ( char, idx ) =>
    {
        char.style.opacity = (idx * 0.1 + 0.1);
    });
     */


    //gsap.registerPlugin( ScrollTrigger );
    gsap.registerPlugin(ScrollTrigger,Observer,ScrollToPlugin,TextPlugin,ScrollSmoother,ScrambleTextPlugin,SplitText);

    const scrollSmooth = ScrollSmoother.create({
        smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
        effects: false, // looks for data-speed and data-lag attributes on elements
        smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
    });

    const split = new SplitText( '.telex', { type: "words,chars", wordsClass : 'splitWord' } );


    document.querySelectorAll( '.splitWord' ).forEach( (word) =>
    {
        const chars = word.querySelectorAll( 'div' );
        gsap.set( chars, { opacity: 0, color: '#60abff' });

    });

    const tl = gsap.timeline();
    gsap.set( ".telex", { perspective: 400 } );

    //gsap.from(split.chars, {duration: 0.1, autoAlpha: 0, stagger: 0.05});

    /*
    split.words.forEach( (word) =>
    {
        const chars = word.querySelectorAll( 'div' );
        gsap.from( chars, {delay: 1, duration: 0.01 * chars.length, opacity: 0, color: '#60abff',autoAlpha: 0, stagger: 0.025});
    });
     */


    /*
    tl.from(chars, {
        duration: 0.2,
        opacity: 0,
        ease: "back",
        stagger: 0.005
    });
     */


    const reveals = gsap.utils.toArray( '.reveal-container' );
    reveals.forEach( ( revealContainer, index ) => {
        const delay = revealContainer.hasAttribute( 'data-delay' ) ? parseFloat( revealContainer.getAttribute( 'data-delay' ) ) : 0;
        console.log( revealContainer.getAttribute( 'data-delay' ), revealContainer );
        gsap.from(revealContainer, {
            scrollTrigger: {
                start: 'top bottom',
                end: 'bottom top',
                trigger: revealContainer,
                onEnter() {
                    console.log( delay );
                    setTimeout( () => {
                        revealContainer.classList.add('animate-in');
                    }, 1000 * delay );

                }
            }
        });
    });

    const subHeadlines = gsap.utils.toArray( '.headline-scramble' );
    subHeadlines.forEach( ( subHeadline ) => {
        gsap.from(subHeadline, {
            scrollTrigger: {
                start: 'top bottom',
                end: 'bottom top',
                trigger: subHeadline,
                onEnter() {
                    if ( subHeadline.innerText.length <= 1 ) {
                        gsap.to( subHeadline, {
                            duration : 2,
                            scrambleText: subHeadline.getAttribute( 'data-text' )
                        });
                    }
                }
            }
        });
    });

    document.querySelector( '[data-id="main-menu"]' ).querySelectorAll( '.nav-link' ).forEach( ( aElem ) =>
    {
        aElem.addEventListener( 'click', ( clickEvent ) => {
            clickEvent.preventDefault();
            scrollSmooth.scrollTo( clickEvent.currentTarget.getAttribute( 'href' ), true );
            console.log( clickEvent.currentTarget );
        });
    });
    document.querySelector( 'a[href="#home"]' ).addEventListener( 'click', ( clickEvent ) =>
    {
        clickEvent.preventDefault();
        scrollSmooth.scrollTo( '#home', true );
    });

    /*
    gsap.registerPlugin( ScrollSmoother );

    ScrollSmoother.create({
        smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
        effects: true, // looks for data-speed and data-lag attributes on elements
        smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
    });
     */

    /*
    gsap.ticker.add( ( time ) =>
    {
        //lenis.raf( time * 1000 );
    });

     */
    //gsap.ticker.lagSmoothing( 0 );


    gsap.to( '#leistungen', {
        scrollTrigger : {
            trigger : "#leistungen",
            start: 'center 90%',
            //end: 'center 50%',
            markers: false,
            //onUpdate : ( self ) => { header.style.top = -75 + ( 75 * self.progress ) +'px';  },
            //onEnter: ( { progress, direction, isActive } ) => { header.style.top = '0px'; console.log( progress, direction, isActive ); },
            onUpdate: ( self ) => {
                if ( self.progress > 0 ) {
                    if ( header.style.top !== '0' ) {
                        header.style.top ='0px';
                    }
                } else if ( header.style.top !== '-75px' ) {
                    header.style.top = '-75px';
                }
            }
            //onLeave: ( { progress, direction, isActive } ) => { if ( true === isActive ) { header.style.top = '-75px';  }; console.log( progress, direction, isActive ); }
        },
    });


    const scrollSections = gsap.utils.toArray( "section" );
    const mainMenu = document.querySelector( '[data-id="main-menu"]' );
    scrollSections.forEach( ( section, index ) =>
    {
        ScrollTrigger.create(
            {
                trigger: section,
                start: "+200px",
                end: "-200px",
                onEnter: () => { enteringSection( section ); },
                onEnterBack: () => { enteringSection( section ); },
                onLeave: () => { leavingSection( section ); },
                onLeaveBack: () => { leavingSection( section ); },
            }
        );

        if ( index === 0 ) return;

        const menuItem = mainMenu.querySelector( '[href="#' + section.getAttribute( 'id' ) + '"]' );
        ScrollTrigger.create(
            {
                trigger: section,
                start: "top +75px",
                end: "bottom +75px",
                onEnter: () => { enteringMenuSection( section ); },
                onEnterBack: () => { enteringMenuSection( section ); },
                onLeave: () => { leavingMenuSection( section ); },
                onLeaveBack: () => { leavingMenuSection( section ); },
            }
        );
    });

    function enteringMenuSection( section )
    {
        const menuItem = mainMenu.querySelector( '[href="#' + section.getAttribute( 'id' ) + '"]' );
        menuItem.classList.add( 'active' );
    }

    function leavingMenuSection( section )
    {
        const menuItem = mainMenu.querySelector( '[href="#' + section.getAttribute( 'id' ) + '"]' );
        menuItem.classList.remove( 'active' );
    }

    function enteringSection( section )
    {
        const nextSection = section.nextElementSibling;
        if ( nextSection && false === nextSection.hasAttribute( 'data-processed' ) )
        {
            console.log( section );

            nextSection.querySelectorAll( '.splitWord' ).forEach( (word) =>
            {
                const chars = word.querySelectorAll( 'div' );
                gsap.fromTo( chars, { opacity: 0, color: '#60abff' }, { delay: .25, duration: 0.01 * chars.length, color: '#000000', opacity: 1, stagger: 0.025, ease : "power1.inOut"});

            });
            nextSection.setAttribute( 'data-processed', true );
        }
    }

    function leavingSection( section )
    {
        const previousSection = section.previousElementSibling;
        if ( previousSection && false === previousSection.hasAttribute( 'data-processed' ) )
        {
            /*
            previousSection.querySelectorAll( '.splitWord' ).forEach( (word) =>
            {
                const chars = word.querySelectorAll( 'div' );
                gsap.fromTo( chars, { opacity: 0, color: '#60abff' }, { delay: .25, duration: 0.01 * chars.length, color: '#000000', opacity: 1, stagger: 0.025, ease : "power1.inOut"});

            });
            previousSection.setAttribute( 'data-processed', true );
             */
        }
    }



    /*
    ScrollTrigger.create(
        {
            trigger: '#services',
            start: "top top",
            end: "bottom top",
            markers: true,
            onEnter: () => { console.log( "SERVICES" ) },
            onEnterBack: () => { console.log( "SERVICES" ) },
            onLeave: () => { console.log( "NO SERVICES" ) },
            onLeaveBack: () => { console.log( "NO SERVICES" ) }
        }
    );
     */

    function raf(time) {
        requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)
}

/*
window.onMainMenuClick = function( aElem )
{
    if ( aElem instanceof HTMLAnchorElement )
    {
        const activeAnchor = document.querySelector( '[data-id="main-menu"]').querySelector( '.active' );
        if ( activeAnchor )
        {
            activeAnchor.classList.remove( 'active' );
        }
        aElem.classList.add( 'active' );
    }
    return false;
}

 */
